var icon = '/img/icons/map-marker.png';
var map;

function initialize() {
  var bounds = new google.maps.LatLngBounds();

	map = new google.maps.Map(document.getElementById('map'), {
  		zoom: 16,
  		mapTypeId: 'roadmap'
  	});

    $.each(locations,function(i,itm) {
      var marker = {
            position: new google.maps.LatLng(itm.lat,itm.lon),
            type: 'info',
            description: itm.name
      };

      bounds.extend(marker.position);

      addMarker(marker);
    });

    map.fitBounds(bounds);
}

function addMarker(feature) {
  var marker = new google.maps.Marker({
    position: feature.position,
    icon: new google.maps.MarkerImage(icon, null, null, null, new google.maps.Size(30,49)),
    map: map
  });
}


$(document).ready(function(){
  $(".closebtn").on('click', function(e){
    e.preventDefault();
    closeNav();
  });

  $(".openbtn").on('click', function(e){
    e.preventDefault();
    if ($("#sidenav").is('.open')) {
      closeNav();
    } else {
      openNav();
    }
  });

  $(".menubuttons a, #header_menu_buttons a").on('click', function(){
     closeNav();
  });

  window.setTimeout(function(){
    $("#contactUs").addClass('show');
  }, 1000);
});

function closeNav() {
  $("#sidenav").removeClass('open');
}

function openNav() {
  $("#sidenav").addClass('open');
}

$(function() {
  $('a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top - $(".header").height()
        }, 1000);
        return false;
      }
    }
  });
});
